import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fa: {
    translation: {
      "app_title": "سامانه درج آگهی در روزنامه",
      "error_occurred": "خطایی رخ داد. لطفا دوباره تلاش کنید.",
      "back": "بازگشت",
      "continue": "ادامه",
      "steps": {
        "select_subject": "انتخاب موضوع",
        "fill_info": "تکمیل اطلاعات",
        "select_newspaper": "انتخاب روزنامه",
        "preview_payment": "پیش‌نمایش و پرداخت"
      },
      "payment": {
        "success": "پرداخت موفق",
        "error": "خطا در پرداخت",
        "ref_id": "شماره پیگیری",
        "publish_date": "تاریخ انتشار",
        "note": "لطفاً شماره پیگیری را یادداشت نمایید"
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "fa",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n; 