import React from 'react';
import './PreviewStep.css';

function PreviewStep({ subject, formData, newspaper, wantsCopy, onConfirm, onBack }) {
    const totalAmount = newspaper.price + (wantsCopy ? 25000 : 0);

    const handleEdit = () => {
        onBack(2);
    };

    return (
        <div className="preview-step">
            <h2>پیش‌نمایش آگهی</h2>
            
            <div className="preview-content">
                <div className="preview-section">
                    <h3>موضوع آگهی</h3>
                    <p>{subject.name}</p>
                </div>

                <div className="preview-section">
                    <h3>اطلاعات آگهی</h3>
                    {Object.entries(formData).map(([key, value]) => {
                        const field = subject.fields.find(f => f.name === key);
                        return (
                            <div key={key} className="preview-field">
                                <strong>{field?.label}:</strong>
                                <span>{value}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="preview-section">
                    <h3>روزنامه انتخابی</h3>
                    <p>{newspaper.name}</p>
                    <p>قیمت پایه: {newspaper.price.toLocaleString()} تومان</p>
                    {wantsCopy && (
                        <p>هزینه نسخه کپی: 25,000 تومان</p>
                    )}
                    <div className="total-amount">
                        <strong>مبلغ قابل پرداخت:</strong>
                        <span>{totalAmount.toLocaleString()} تومان</span>
                    </div>
                </div>
            </div>

            <div className="preview-actions">
                <button onClick={handleEdit} className="edit-button">
                    ویرایش اطلاعات
                </button>
                <button onClick={onConfirm} className="confirm-button">
                    تأیید و پرداخت
                </button>
            </div>
        </div>
    );
}

export default PreviewStep; 