import React from 'react';
import { subjects } from '../data/subjects';
import './SubjectSelection.css';

function SubjectSelection({ onSelect }) {
    return (
        <div className="subject-selection">
            <h2>انتخاب موضوع آگهی</h2>
            <div className="subjects-grid">
                {subjects.map(subject => (
                    <div 
                        key={subject.id} 
                        className="subject-card"
                        onClick={() => onSelect(subject)}
                    >
                        <h3>{subject.name}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SubjectSelection; 