import React from 'react';
import './LoadingOverlay.css';

function LoadingOverlay({ message = 'لطفا صبر کنید...' }) {
    return (
        <div className="loading-overlay">
            <div className="loading-content">
                <div className="loading-spinner"></div>
                <p>{message}</p>
            </div>
        </div>
    );
}

export default LoadingOverlay; 