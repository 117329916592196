export const newspapers = [
    {
        id: 1,
        name: 'روزنامه اطلاعات',
        price: 150000,
        description: 'روزنامه سراسری با پوشش ملی'
    },
    {
        id: 2,
        name: 'روزنامه همشهری',
        price: 120000,
        description: 'روزنامه محلی تهران'
    },
    {
        id: 3,
        name: 'روزنامه ایران',
        price: 140000,
        description: 'روزنامه سراسری'
    },
    {
        id: 4,
        name: 'روزنامه جام جم',
        price: 130000,
        description: 'روزنامه سراسری صبح'
    },
    {
        id: 5,
        name: 'روزنامه کیهان',
        price: 145000,
        description: 'روزنامه سراسری عصر'
    }
];

export const copyFee = 25000; // هزینه اضافی برای دریافت نسخه کپی 