export const subjects = [
    {
        id: 1,
        name: 'آگهی فوت',
        fields: [
            { name: 'deceased_name', label: 'نام متوفی', type: 'text', required: true },
            { name: 'death_date', label: 'تاریخ فوت', type: 'date', required: true },
            { name: 'ceremony_location', label: 'محل مراسم', type: 'text', required: true },
            { name: 'ceremony_date', label: 'تاریخ مراسم', type: 'date', required: true },
            { name: 'relatives', label: 'بازماندگان', type: 'textarea', required: true }
        ]
    },
    {
        id: 2,
        name: 'آگهی استخدام',
        fields: [
            { name: 'company_name', label: 'نام شرکت', type: 'text', required: true },
            { name: 'position', label: 'موقعیت شغلی', type: 'text', required: true },
            { name: 'requirements', label: 'شرایط احراز', type: 'textarea', required: true },
            { name: 'salary', label: 'حقوق و مزایا', type: 'text', required: false },
            { name: 'contact_info', label: 'اطلاعات تماس', type: 'text', required: true }
        ]
    },
    {
        id: 3,
        name: 'آگهی مناقصه',
        fields: [
            { name: 'tender_title', label: 'عنوان مناقصه', type: 'text', required: true },
            { name: 'organization', label: 'نام سازمان', type: 'text', required: true },
            { name: 'deadline', label: 'مهلت شرکت', type: 'date', required: true },
            { name: 'details', label: 'جزئیات مناقصه', type: 'textarea', required: true },
            { name: 'requirements', label: 'شرایط شرکت', type: 'textarea', required: true }
        ]
    },
    {
        id: 4,
        name: 'آگهی مزایده',
        fields: [
            { name: 'auction_title', label: 'عنوان مزایده', type: 'text', required: true },
            { name: 'items', label: 'اقلام مزایده', type: 'textarea', required: true },
            { name: 'base_price', label: 'قیمت پایه', type: 'text', required: true },
            { name: 'deadline', label: 'مهلت شرکت', type: 'date', required: true },
            { name: 'conditions', label: 'شرایط شرکت', type: 'textarea', required: true }
        ]
    },
    {
        id: 5,
        name: 'دعوت به مجمع',
        fields: [
            { name: 'company_name', label: 'نام شرکت', type: 'text', required: true },
            { name: 'meeting_date', label: 'تاریخ مجمع', type: 'date', required: true },
            { name: 'meeting_time', label: 'ساعت مجمع', type: 'time', required: true },
            { name: 'location', label: 'محل برگزاری', type: 'text', required: true },
            { name: 'agenda', label: 'دستور جلسه', type: 'textarea', required: true }
        ]
    },
    {
        id: 6,
        name: 'تغییرات شرکت',
        fields: [
            { name: 'company_name', label: 'نام شرکت', type: 'text', required: true },
            { name: 'registration_number', label: 'شماره ثبت', type: 'text', required: true },
            { name: 'changes', label: 'شرح تغییرات', type: 'textarea', required: true },
            { name: 'date', label: 'تاریخ تغییرات', type: 'date', required: true }
        ]
    },
    {
        id: 7,
        name: 'آگهی دعوت به همکاری',
        fields: [
            { name: 'organization', label: 'نام سازمان', type: 'text', required: true },
            { name: 'positions', label: 'موقعیت‌های شغلی', type: 'textarea', required: true },
            { name: 'requirements', label: 'شرایط عمومی', type: 'textarea', required: true },
            { name: 'benefits', label: 'مزایا', type: 'textarea', required: false },
            { name: 'how_to_apply', label: 'نحوه ارسال رزومه', type: 'text', required: true }
        ]
    },
    {
        id: 8,
        name: 'گواهی حصر وراثت',
        fields: [
            { name: 'deceased_name', label: 'نام متوفی', type: 'text', required: true },
            { name: 'death_date', label: 'تاریخ فوت', type: 'date', required: true },
            { name: 'last_residence', label: 'آخرین اقامتگاه', type: 'text', required: true },
            { name: 'heirs', label: 'مشخصات وراث', type: 'textarea', required: true }
        ]
    },
    {
        id: 9,
        name: 'مفقودی مدارک',
        fields: [
            { name: 'document_type', label: 'نوع مدرک', type: 'text', required: true },
            { name: 'owner_name', label: 'نام صاحب مدرک', type: 'text', required: true },
            { name: 'document_details', label: 'مشخصات مدرک', type: 'textarea', required: true },
            { name: 'finder_contact', label: 'اطلاعات تماس یابنده', type: 'text', required: true }
        ]
    },
    {
        id: 10,
        name: 'سایر آگهی‌ها',
        fields: [
            { name: 'title', label: 'عنوان آگهی', type: 'text', required: true },
            { name: 'content', label: 'متن آگهی', type: 'textarea', required: true },
            { name: 'contact', label: 'اطلاعات تماس', type: 'text', required: true }
        ]
    }
]; 