import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './PaymentResult.css';

function PaymentSuccess() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const refId = searchParams.get('refId');
    const date = searchParams.get('date');

    return (
        <div className="payment-result success">
            <div className="result-icon">✓</div>
            <h2>پرداخت با موفقیت انجام شد</h2>
            <div className="result-details">
                <p>شماره پیگیری: {refId}</p>
                <p>تاریخ انتشار: {date}</p>
                <p>آگهی شما در تاریخ مشخص شده در روزنامه منتشر خواهد شد.</p>
                {refId && (
                    <p className="note">
                        لطفاً شماره پیگیری را یادداشت نمایید.
                    </p>
                )}
            </div>
            <button 
                className="home-button"
                onClick={() => navigate('/')}
            >
                بازگشت به صفحه اصلی
            </button>
        </div>
    );
}

export default PaymentSuccess; 