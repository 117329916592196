import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainForm from './components/MainForm';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentError from './components/PaymentError';
import ErrorBoundary from './components/ErrorBoundary';
import { ToastProvider } from './context/ToastContext';
import './App.css';

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainForm />,
    },
    {
        path: "/payment/success",
        element: <PaymentSuccess />,
    },
    {
        path: "/payment/error",
        element: <PaymentError />,
    },
]);

function App() {
    return (
        <ErrorBoundary>
            <ToastProvider>
                <RouterProvider router={router} />
            </ToastProvider>
        </ErrorBoundary>
    );
}

export default App; 