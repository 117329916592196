import React, { useState } from 'react';
import moment from 'moment-jalaali';
import './RequestForm.css';

moment.loadPersian({ dialect: 'persian-modern', usePersianDigits: true });

function RequestForm({ subject, onSubmit, initialData = null }) {
    const [formData, setFormData] = useState(
        initialData || subject.fields.reduce((acc, field) => ({
            ...acc,
            [field.name]: ''
        }), {})
    );
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDateField, setSelectedDateField] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(moment());

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateClick = (fieldName) => {
        setSelectedDateField(fieldName);
        setShowDatePicker(true);
        setCurrentMonth(moment());
    };

    const handleDateSelect = (date) => {
        if (selectedDateField) {
            setFormData(prev => ({
                ...prev,
                [selectedDateField]: date
            }));
            setShowDatePicker(false);
            setSelectedDateField(null);
        }
    };

    const generateCalendar = () => {
        const startOfMonth = moment(currentMonth).startOf('jMonth');
        const endOfMonth = moment(currentMonth).endOf('jMonth');
        const startDate = moment(startOfMonth).subtract(startOfMonth.day(), 'days');
        const calendar = [];
        const totalDays = 42; // 6 weeks

        for (let i = 0; i < totalDays; i++) {
            const currentDate = moment(startDate).add(i, 'days');
            calendar.push({
                date: currentDate.format('jYYYY/jMM/jDD'),
                isCurrentMonth: currentDate.isBetween(startOfMonth.subtract(1, 'day'), endOfMonth.add(1, 'day')),
                isToday: currentDate.isSame(moment(), 'day')
            });
        }

        return calendar;
    };

    const handleMonthChange = (increment) => {
        setCurrentMonth(moment(currentMonth).add(increment, 'jMonth'));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (window.confirm('آیا مطمئن هستید که اطلاعات را به درستی وارد کرده‌اید؟')) {
            onSubmit(formData);
        }
    };

    const weekDays = ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج'];

    return (
        <div className="request-form">
            <h2>{subject.name}</h2>
            <form onSubmit={handleSubmit}>
                {subject.fields.map(field => (
                    <div key={field.name} className="form-group">
                        <label htmlFor={field.name}>{field.label}</label>
                        {field.type === 'textarea' ? (
                            <textarea
                                id={field.name}
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleChange}
                                required={field.required}
                            />
                        ) : field.type === 'date' ? (
                            <div className="date-input-wrapper">
                                <div 
                                    className="jalali-date-display"
                                    onClick={() => handleDateClick(field.name)}
                                >
                                    {formData[field.name] || 'انتخاب تاریخ'}
                                </div>
                                {showDatePicker && selectedDateField === field.name && (
                                    <div className="date-picker-calendar">
                                        <div className="calendar-header">
                                            <button type="button" onClick={() => handleMonthChange(-1)}>‹</button>
                                            <span>{currentMonth.format('jMMMM jYYYY')}</span>
                                            <button type="button" onClick={() => handleMonthChange(1)}>›</button>
                                        </div>
                                        <div className="calendar-weekdays">
                                            {weekDays.map(day => (
                                                <div key={day} className="weekday">{day}</div>
                                            ))}
                                        </div>
                                        <div className="calendar-days">
                                            {generateCalendar().map((day, index) => (
                                                <div
                                                    key={index}
                                                    className={`calendar-day ${!day.isCurrentMonth ? 'other-month' : ''} 
                                                              ${day.isToday ? 'today' : ''}`}
                                                    onClick={() => day.isCurrentMonth && handleDateSelect(day.date)}
                                                >
                                                    {day.date.split('/')[2]}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <input
                                type={field.type}
                                id={field.name}
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleChange}
                                required={field.required}
                            />
                        )}
                    </div>
                ))}
                <button type="submit" className="submit-button">ادامه</button>
            </form>
        </div>
    );
}

export default RequestForm; 