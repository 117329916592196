import React, { useEffect } from 'react';
import './Toast.css';

function Toast({ message, type = 'info', onClose, duration = 5000 }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div className={`toast ${type}`}>
            <div className="toast-content">
                {message}
            </div>
            <button className="toast-close" onClick={onClose}>
                ×
            </button>
        </div>
    );
}

export default Toast; 