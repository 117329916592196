import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './PaymentResult.css';

function PaymentError() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const message = searchParams.get('message') || 'خطا در پردازش پرداخت';

    return (
        <div className="payment-result error">
            <div className="result-icon">✕</div>
            <h2>خطا در پرداخت</h2>
            <div className="result-details">
                <p>{message}</p>
                <p>در صورت کسر وجه از حساب شما، مبلغ تا ۷۲ ساعت آینده عودت داده خواهد شد.</p>
            </div>
            <button 
                className="retry-button"
                onClick={() => window.history.back()}
            >
                تلاش مجدد
            </button>
            <button 
                className="home-button"
                onClick={() => navigate('/')}
            >
                بازگشت به صفحه اصلی
            </button>
        </div>
    );
}

export default PaymentError; 