export const colors = {
    primary: {
        main: '#2B6CB0',      // Medium Blue
        light: '#4299E1',     // Light Blue
        dark: '#2C5282',      // Dark Blue
        contrast: '#FFFFFF'    // White
    },
    secondary: {
        main: '#63B3ED',      // Sky Blue
        light: '#90CDF4',     // Lighter Sky Blue
        dark: '#3182CE',      // Darker Sky Blue
        contrast: '#FFFFFF'    // White
    },
    accent: {
        main: '#4FD1C5',      // Teal
        light: '#81E6D9',     // Light Teal
        dark: '#319795',      // Dark Teal
        contrast: '#FFFFFF'    // White
    },
    success: {
        main: '#38B2AC',      // Teal Green
        light: '#4FD1C5',
        dark: '#2C7A7B',
        contrast: '#FFFFFF'
    },
    error: {
        main: '#E53E3E',      // Red
        light: '#FC8181',
        dark: '#C53030',
        contrast: '#FFFFFF'
    },
    warning: {
        main: '#ED8936',      // Orange
        light: '#F6AD55',
        dark: '#C05621',
        contrast: '#FFFFFF'
    },
    background: {
        default: '#EBF8FF',   // Very Light Blue
        paper: '#FFFFFF',     // White
        dark: '#BEE3F8'       // Light Blue Gray
    },
    text: {
        primary: '#2D3748',   // Dark Gray Blue
        secondary: '#4A5568',  // Medium Gray Blue
        disabled: '#A0AEC0'   // Light Gray Blue
    },
    border: {
        light: '#E2E8F0',
        main: '#CBD5E0',
        dark: '#A0AEC0'
    }
}; 