import React, { useState } from 'react';
import { newspapers, copyFee } from '../data/newspapers';
import './NewspaperSelection.css';

function NewspaperSelection({ onSelect }) {
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [wantsCopy, setWantsCopy] = useState(false);

    const handleSelection = (paper) => {
        setSelectedPaper(paper);
    };

    const handleProceed = () => {
        if (selectedPaper) {
            onSelect(selectedPaper, wantsCopy);
        }
    };

    const calculateTotalPrice = (paper) => {
        if (!paper) return 0;
        return paper.price + (wantsCopy ? copyFee : 0);
    };

    return (
        <div className="newspaper-selection">
            <h2>انتخاب روزنامه</h2>
            <div className="newspapers-list">
                {newspapers.map(paper => (
                    <div 
                        key={paper.id} 
                        className={`newspaper-item ${selectedPaper?.id === paper.id ? 'selected' : ''}`}
                        onClick={() => handleSelection(paper)}
                    >
                        <h3>{paper.name}</h3>
                        <p className="description">{paper.description}</p>
                        <p className="price">قیمت: {paper.price.toLocaleString()} تومان</p>
                    </div>
                ))}
            </div>

            <div className="copy-option">
                <label>
                    <input 
                        type="checkbox" 
                        checked={wantsCopy} 
                        onChange={() => setWantsCopy(!wantsCopy)} 
                    />
                    دریافت نسخه کپی (هزینه اضافی: {copyFee.toLocaleString()} تومان)
                </label>
            </div>

            {selectedPaper && (
                <div className="total-price">
                    مبلغ قابل پرداخت: {calculateTotalPrice(selectedPaper).toLocaleString()} تومان
                </div>
            )}

            <button 
                className="proceed-button" 
                onClick={handleProceed} 
                disabled={!selectedPaper}
            >
                پرداخت و ثبت آگهی
            </button>
        </div>
    );
}

export default NewspaperSelection; 