import React, { useRef, useEffect } from 'react';
import './StepIndicator.css';

function StepIndicator({ currentStep }) {
    const stepRef = useRef(null);
    const containerRef = useRef(null);

    const steps = [
        { number: 1, title: 'انتخاب موضوع' },
        { number: 2, title: 'تکمیل اطلاعات' },
        { number: 3, title: 'انتخاب روزنامه' },
        { number: 4, title: 'پیش‌نمایش و پرداخت' }
    ];

    useEffect(() => {
        if (stepRef.current && containerRef.current) {
            const container = containerRef.current;
            const activeStep = stepRef.current;
            
            const scrollPosition = activeStep.offsetLeft - (container.offsetWidth / 2) + (activeStep.offsetWidth / 2);
            
            container.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [currentStep]);

    return (
        <div 
            className="step-indicator"
            ref={containerRef}
            style={{ '--current-step': currentStep }}
        >
            {steps.map((step) => (
                <div 
                    key={step.number} 
                    className={`step ${currentStep === step.number ? 'active' : ''} 
                               ${currentStep > step.number ? 'completed' : ''}`}
                    ref={currentStep === step.number ? stepRef : null}
                >
                    <div className="step-number">
                        {currentStep > step.number ? '✓' : step.number}
                    </div>
                    <div className="step-title">{step.title}</div>
                </div>
            ))}
        </div>
    );
}

export default StepIndicator; 