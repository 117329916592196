import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubjectSelection from './SubjectSelection';
import RequestForm from './RequestForm';
import NewspaperSelection from './NewspaperSelection';
import PreviewStep from './PreviewStep';
import LoadingOverlay from './LoadingOverlay';
import { useToast } from '../context/ToastContext';
import { colors } from '../theme/colors';
import StepIndicator from './StepIndicator';

function MainForm() {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const [step, setStep] = useState(1);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [formData, setFormData] = useState(null);
    const [selectedNewspaper, setSelectedNewspaper] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubjectSelect = (subject) => {
        setSelectedSubject(subject);
        setStep(2);
        showToast(`موضوع "${subject.name}" انتخاب شد`, 'success', 3000);
    };

    const handleFormSubmit = (data) => {
        setFormData(data);
        setStep(3);
    };

    const handleNewspaperSelect = (newspaper, wantsCopy) => {
        setSelectedNewspaper({ ...newspaper, wantsCopy });
        setStep(4); // Go to preview step
    };

    const handlePaymentSubmit = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    subject: selectedSubject,
                    formData: formData,
                    newspaper: selectedNewspaper,
                    wantsCopy: selectedNewspaper.wantsCopy,
                    totalAmount: selectedNewspaper.price + (selectedNewspaper.wantsCopy ? 25000 : 0)
                }),
            });

            const result = await response.json();
            
            if (result.paymentUrl) {
                window.location.href = result.paymentUrl;
            } else {
                showToast(t('error_occurred'), 'error');
            }
        } catch (error) {
            console.error('Error submitting request:', error);
            showToast(t('error_occurred'), 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = (targetStep) => {
        if (targetStep) {
            setStep(targetStep);
        } else if (step > 1) {
            setStep(step - 1);
        }

        if (targetStep === 2 || step === 2) {
            setSelectedNewspaper(null);
        }
    };

    return (
        <div className="app-container">
            {isLoading && <LoadingOverlay />}
            
            <header>
                <div className="header-content">
                    <h1>{t('app_title')}</h1>
                    <p className="header-subtitle">
                        ثبت سریع و آسان آگهی در معتبرترین روزنامه‌های کشور
                    </p>
                    <div className="header-decoration"></div>
                </div>
            </header>

            <main>
                <StepIndicator currentStep={step} />
                
                {step > 1 && (
                    <button 
                        onClick={() => handleBack()}
                        className="back-button"
                        style={{ backgroundColor: colors.primary.main }}
                    >
                        {t('back')}
                    </button>
                )}

                {step === 1 && (
                    <SubjectSelection 
                        onSelect={handleSubjectSelect} 
                    />
                )}

                {step === 2 && selectedSubject && (
                    <RequestForm 
                        subject={selectedSubject}
                        onSubmit={handleFormSubmit}
                        initialData={formData}
                    />
                )}

                {step === 3 && (
                    <NewspaperSelection 
                        onSelect={handleNewspaperSelect}
                    />
                )}

                {step === 4 && (
                    <PreviewStep
                        subject={selectedSubject}
                        formData={formData}
                        newspaper={selectedNewspaper}
                        wantsCopy={selectedNewspaper.wantsCopy}
                        onConfirm={handlePaymentSubmit}
                        onBack={handleBack}
                    />
                )}
            </main>
        </div>
    );
}

export default MainForm; 